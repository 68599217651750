import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    $(this.element).select2({
      theme: 'bootstrap-5'
    })
  }
}

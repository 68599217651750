import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'startDate', 'endDate' ]

  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('start_date')) {
      this.startDateTarget.value = urlParams.get('start_date')
    }
    if (urlParams.get('end_date')) {
      this.endDateTarget.value = urlParams.get('end_date')
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'all', 'option' ]

  toggle() {
    for (let option of this.optionTargets) {
      option.checked = !option.checked
    }
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery
import "@popperjs/core"

// Fontawesome
import "@fortawesome/fontawesome-free/js/all"
FontAwesome.config.mutateApproach = 'sync'

// // Pagy
// import './pagy.js.erb'

// // Select2
import select2 from 'select2'

// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
// // const images = require.context('../images', true)
// // const imagePath = (name) => images(name, true)

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interfaces"
export default class extends Controller {
  static targets = ["interfaces", "status"]

  connect() {
    this.toggleInterfaces("up")
  }

  toggleInterfaces() {
    const status = this.statusTarget.value
    for (const i of this.interfacesTarget.querySelectorAll("tr")) {
      if (i.dataset.operationalStatus == status) {
        i.classList.remove("d-none")
      } else {
        i.classList.add("d-none")
      }
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "networkName" ]

  connect() {
    document.addEventListener("turbo:frame-load", (event) => {
      if (event.target.id == "network-identity-certificate") {
        const linkText = document.createTextNode(this.networkNameTarget.textContent)
        const link = document.createElement("a")
        link.appendChild(linkText)
        link.href = `https://${this.networkNameTarget.textContent}`
        link.setAttribute("target", "_blank")
        this.networkNameTarget.replaceWith(link)
      }
    })
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const id = this.element.id.split('-')[2]
    const route = window.location.href.split('?')[0]
    this.element.href = `${route}/${id}`
  }
}

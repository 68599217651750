import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "record" ]

  connect() {
    this.element.addEventListener("turbo:click", (event) => {
      // Create turbo frame target for the content of the list item
      const turboFrameId = event.target.dataset.turboFrame
      $("#list-content").html(`<turbo-frame id="${turboFrameId}"></turbo-frame>`)

      // Remove active state from previous selection
      const activeListGroupItem = this.element.querySelector(".active")
      
      if (activeListGroupItem) {
        activeListGroupItem.classList.remove("active")
        const activeListGroupItemLink = activeListGroupItem.querySelector("a")
        activeListGroupItemLink.classList.remove("text-white")
        activeListGroupItemLink.classList.add("text-dark")
      }

      // Add active state to current selection
      event.target.parentNode.classList.add("active")
      event.target.classList.remove("text-dark")
      event.target.classList.add("text-white")
    })
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  run(event) {
    const filterables = this.element.querySelectorAll('[data-filterable]')
    const input = new RegExp(event.target.value, "i")
    for (let filterable of filterables) {
      if (filterable.innerHTML.match(input)) {
        filterable.classList.remove("d-none")
      } else {
        filterable.classList.add("d-none")
      }
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "controllerName" ]

  connect() {
    $(this.element).on('click', function(e) {
      $('#content').html(`<turbo-frame id="${this.getAttribute('data-turbo-frame')}">`)
    })
  }
}

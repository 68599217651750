import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'location' ]

  connect() {
    $('#role').on('change.select2', (event) => {
      this.location(event)
    })
  }

  location(event) {
    if (event.target.value.match(/staff|admin/)) {
      $(this.locationTarget).removeClass('d-none')
    } else {
      const target = $(this.locationTarget)
      target.addClass('d-none')
      target.find('select').val(null).trigger('change')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mac-address-component"
export default class extends Controller {
  static targets = ["learnedMacAddress", "switches"]

  connect() {
    this.switches = this.switchesTarget.dataset.switches.split(",")
    for (let learnedMacAddress of this.learnedMacAddressTargets) {
      console.log(learnedMacAddress.children[2].innerHTML.trim())
      if (this.switches.includes(learnedMacAddress.children[2].innerHTML.trim())) {
        learnedMacAddress.classList.add("d-none")
      }
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $("button[data-toggle='tab']").on("shown.bs.tab", (event) => {
      const activeTab = event.target.dataset.target.substring(1)
      const links = this.element.querySelectorAll(".pagy-bootstrap-nav a")
      for (let link of links) {
        const url = new URL(link.href)
        url.searchParams.set("tab", activeTab)
        link.href = url
      }
    })
  }
}
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const nav = this.element.querySelector(".nav")
    const tabs = document.querySelectorAll(".tab-pane")
    for (let tab of tabs) {
      nav.removeChild(tab)
    }
    const content = this.element.querySelector(".tab-content")
    for (let tab of tabs) {
      content.appendChild(tab)
    }
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'total' ]

  connect() {
    this.subtotal = 0
    this.total = this.totalTarget.children[0].innerHTML
  }

  highlight(event) {
    event.target.classList.toggle('bg-info')
    if (event.target.classList.contains('bg-info')) {
      this.subtotal += this.parseCurrency(event.target.children[0].innerHTML)
    } else {
      this.subtotal -= this.parseCurrency(event.target.children[0].innerHTML)
    }
    this.totalTarget.children[0].innerHTML = `${this.total} (${this.subtotal})`
  }

  parseCurrency(currency) {
    return parseFloat(currency.replace(/[$,]/g, ''))
  }
}

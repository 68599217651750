import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'total' ]

  connect() {
    const url = new URL(window.location)
    if (url.searchParams.has('fiscal_year')) {
      const fiscal_year = url.searchParams.get('fiscal_year')
      const select = document.getElementById('fiscal_year')
      select.value = fiscal_year
    }
    if (url.searchParams.has('type')) {
      const fiscal_year = url.searchParams.get('type')
      const select = document.getElementById('type')
      select.value = fiscal_year
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'networkName', 'location', 'user' ]

  connect() {
    $(this.element).find('#item_location_id').on('change', () => {
      this.location()
    })
    $(this.element).find('#item_user_id').on('change', () => {
      this.user()
    })
  }

  async location() {
    if (this.locationTarget.value === '') { return }
    const locationResponse = await fetch(`/locations/${this.locationTarget.value}.json`)
    const location = await locationResponse.json()
    const assetTag = await this.assetTag()
    this.networkNameTarget.value = `${location.room}-${assetTag}`
    $(this.userTarget).val(null).trigger('change')
  }

  async user() {
    if (this.userTarget.value === '') { return }
    const userResponse = await fetch(`/users/${this.userTarget.value}.json`)
    const user = await userResponse.json()
    const assetTag = await this.assetTag()
    this.networkNameTarget.value = `${user.username}-${assetTag}`
    $(this.locationTarget).val(null).trigger('change')
  }

  async assetTag() {
    const frame_id = $(this.element).closest('turbo-frame').attr('id')
    const itemId = frame_id.split('_')[1]
    const response = await fetch(`/items/${itemId}.json`)
    const item = await response.json()
    return item.asset_tag
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.dismiss();
    }, 1500);
  }

  dismiss() {
    $(this.element).fadeOut(1500);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "controllerName" ]

  connect() {
    $('#list').on('click', function (e) {
      const link = $(event.target).closest('a')[0]
      for (let item of $(this).children()) {
        const item_link = $(item).find('a')[0]
        if (item_link == link) {
          $(item_link).addClass('active')
        } else {
          $(item_link).removeClass('active')
        }
      }
    })
  }
}
